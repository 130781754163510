import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        component: () => import("@/views/MainPageGuard.vue"),
        children: [
            {
                path: "",
                name: "index",
                component: () => import("@/views/Index.vue")
            },
            // {
            //     path: "jangun",
            //     name: "jangun",
            //     component: () => import("@/views/routeInfo/jangun.vue")
            // },
            {
                path: "introduce",
                name: "introduce",
                component: () => import("@/views/introduce/IntroduceHome.vue")
            },
            {
                path: "meeting",
                name: "meeting",
                component: () => import("@/views/meeting/MeetingHome.vue")
            },
            {
                path: "route",
                name: "route/1",
                component: () => import("@/views/route/Route1.vue")
            },
            {
                path: "route/2",
                name: "route/2",
                component: () => import("@/views/route/Route2.vue")
            },
            {
                path: "route/3",
                name: "route/3",
                component: () => import("@/views/route/Route3.vue")
            },
            {
                path: "route/4",
                name: "route/4",
                component: () => import("@/views/route/Route4.vue")
            },
            {
                path: "route/5",
                name: "route/5",
                component: () => import("@/views/route/Route5.vue")
            },
            {
                path: "notice",
                name: "notice",
                component: () => import("@/views/board/NoticeHome.vue")
            },
            {
                path: "notice/detail/:seq",
                name: "noticeDetail",
                component: () => import("@/views/board/NoticeDetail.vue")
            },
        ]

    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    /* 페이지 이동시 최상단으로 스크롤 이동 */
    scrollBehavior() { return { x: 0, y: 0 }; },
})

export default router

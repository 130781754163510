/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: Alert Dialog
┣ @작성: 신우균, 2022-05-30                     
┣ @내역: 신우균, 2022-05-30, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <v-dialog
        :value="dialogToggle"
        persistent
        transition
        scrollable
        max-width="600"
    >
        <v-card>
            <v-card-title 
                class="dialog-title" 
                :style="dialogTitleColor()"
            >
                <h2>{{ title }}</h2>
                <v-spacer></v-spacer>
                <!-- Dialog close -->
                <v-icon
                    color="black"
                    @click="dialogClose()"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="dialog-body">
                <!-- 저장 확인 -->
                <div class="alert__message" v-if="type === 'save'">
                    <v-icon
                        size="70"
                        color="#000"
                        class="status-icon"
                    >
                        mdi-alert-circle-check-outline
                    </v-icon>
                    <h2>저장하시겠습니까?</h2>
                </div>

                <!-- 수정 확인 -->
                <div class="alert__message" v-if="type === 'modify'">
                    <v-icon
                        size="70"
                        color="#000"
                        class="status-icon"
                    >
                        mdi-alert-circle-check-outline
                    </v-icon>
                    <h2>수정하시겠습니까?</h2>
                </div>

                <!-- 삭제 확인 -->
                <div class="alert__message" v-if="type === 'delete'">
                    <v-icon
                        size="70"
                        color="#000"
                        class="status-icon"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                    <h2>삭제하시겠습니까?</h2>
                </div>

                <!-- 정상처리 안내 -->
                <div class="alert__message" v-if="type === 'ok'">
                    <v-icon
                        size="70"
                        color="#000"
                        class="status-icon"
                    >
                        mdi-check-circle-outline
                    </v-icon>
                    <h2>정상처리되었습니다.</h2>
                </div>

                <!-- 에러 안내 -->
                <div v-if="type === 'error'">
                    <div class="alert__message" >
                        <v-icon
                            size="70"
                            color="#000"
                            class="status-icon"
                        >
                            mdi-alert-circle-outline
                        </v-icon>
                        <h2>에러가 발생했습니다.</h2>
                    </div>
                    <div class="error-table-wrap">
                        <table class="error-table">
                            <colgroup>
                                <col width="100px">
                                <col width="*">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="text-center">에러코드</td>
                                    <td>{{ config.error.code }}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">에러내용</td>
                                    <td>{{ config.error.message }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- 커스텀 메시지 -->
                <div class="alert__message" v-if="type === 'customMessage'">
                    <v-icon
                        size="70"
                        color="#000"
                        class="status-icon"
                    >
                        {{ config.custom.icon }}
                    </v-icon>
                    <h2 class="text-contents" v-html="config.custom.message"></h2>
                </div>

                <!-- 커스텀 슬롯 -->
                <slot name="customSlot" v-if="type === 'customSlot'"></slot>
            </v-card-text>
            <v-card-actions class="dialog-actions">
                <v-container>
                    <div v-if="actionsButtonSelect('confirmButton')">
                        <v-btn
                            elevation="2"
                            color="error"
                            tabindex="7"
                            large
                            :disabled="isLoading"
                            @click="dialogClose()"
                        >
                            아니오 
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="confirmClick(type)"
                            :loading="isLoading"
                            large
                        >
                            예
                        </v-btn>
                    </div>
                    <div v-if="actionsButtonSelect('okButton')" class="text-center">
                        <v-btn
                            class=""
                            color="primary"
                            @click="dialogClose()"
                            large
                        >
                            확인
                        </v-btn>
                    </div>
                    <slot name="customSlotActions" v-if="type === 'customSlot'"></slot>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialogToggle: {
            type: Boolean,
            default: false,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        config: {
            type: Object,
            default: () => {
                return {
                    title: "",
                    titleColor: "",
                    error: {
                        code: 0,
                        message: ""
                    },
                    custom: {
                        icon: "",
                        message: "",
                    }
                }
            }
        }
    },
    data() {
        return {
            title: "",
            isLoading: false,
        }
    },
    watch: {
        dialogToggle: {
            // Dialog show event handler
            handler() {
                if (this.dialogToggle === true) {
                    this.isLoading = false;
                    this.init();
                }
            }
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        /**********************************************************************
         *  @method     : Dialog init
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        init() {
            if ("title" in this.config) {
                this.title = this.config.title !== "" 
                           ? this.title = this.config.title 
                           : "알림";
            } else {
                this.title = "알림";
            }
        },

        /**********************************************************************
         *  @method     : Dialog Titlebar background color
         *  @param      { undefined }
         *  @returns    { object }      :Css style key-value object
         *********************************************************************/
        dialogTitleColor() {
            switch(this.type) {
                case "modify":
                    return { "background": "#2196F3" }

                case "delete": 
                    return { "background": "#EF5350" }

                case "error":
                    return { "background": "#EF5350" }

                default: 
                    if ("titleColor" in this.config) {
                        return { "background": this.config.titleColor}
                    }
            }
        },

        /**********************************************************************
         *  @method     : Dialog actions button v-if
         *  @param      { string }
         *  @returns    { boolean }
         *********************************************************************/
        actionsButtonSelect(type) {
            if (this.type === "customSlot") {
                return false;
            }
            const okButtonModeList = [ "ok", "error", "customMessage" ];
            const okButtonActive = okButtonModeList.includes(this.type);
            if (okButtonActive) {
                return type === "okButton" ? true : false;
            } else {
                return type === "okButton" ? false : true;
            } 
        },

        /**********************************************************************
         *  @method     : Dialog confirm button click event handler
         *  @param      { string }
         *  @returns    { undefined }
         *********************************************************************/
        confirmClick(target) {
            switch(target) {
                case "save":
                    this.isLoading = true;
                    this.$emit("confirmSave");
                    break;

                case "modify":
                    this.isLoading = true;
                    this.$emit("confirmModify");
                    break;

                case "delete": 
                    this.isLoading = true;
                    this.$emit("confirmDelete");
                    break;
            }
        },

        /**********************************************************************
         *  @method     : dialog close
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        dialogClose() {
            // Dialog close
            this.$emit("dialogClose");
        },
    }
}
</script>

<style lang="scss" scoped>
    .status-icon {
        margin-right: 10px;
    }
    .dialog-title {
        background: #CDDC39;
        padding: 16px 24px !important;
    }
    .dialog-body {
        padding-top: 24px !important;
        width: 100%;
        div { width: 100% }
    }
    .dialog-actions {
        padding: 15px 24px !important;
        .container { 
            margin:0px; 
            padding: 0px; 
            display: flex;
            justify-content: flex-end;
        }
        .v-btn {
            font-size: 16px;
            min-width: 100px;
            margin-left: 10px;
        }
    }
    .alert__message {
        width: 100%;
        display: flex;
        align-items: center;
        color: #000;
    }
    .text-center {
        text-align: center;
    }
    .text-contents{
        line-height: 34px;
    }
    .error-table-wrap {
        margin-top: 15px;
    }
    .error-table {
        $border-default: 1px solid #d9d9d9;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dee2e6;
        color: #212519;
        border-top: 2px solid #000;

        tbody {
            tr {
                border-bottom: $border-default;
                td {
                    padding: 10px;
                    border-right: $border-default;
                    word-break: keep-all;
                    font-size: 16px;
                    &:last-child:not(.border-draw) {
                        border-right: none;
                    }
                }
            }
        }
    }
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios"
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
// Global components
import AlertDialog from "@/components/common/AlertDialog.vue";
import TablePagination from "@/components/common/Pagination";

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.component("AlertDialog", AlertDialog);
Vue.component("TablePagination", TablePagination);
Vue.mixin({
    methods: {
        /**********************************************************************
         *  @method     : Date Time Formatting
         *  @param      { string }
         *  @returns    { string }
         *********************************************************************/
        dbDateTimeFormatting(dateTime) {
            let resp = dateTime.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/g, '$1-$2-$3 $4:$5:$6');
            resp = resp.replace("T", " ");
            return resp;
        },
        
        /**********************************************************************
         *  @method     : Axios error handling
         *  @param      { Number }
         *  @returns    { object }
         *********************************************************************/
        axiosErrorHandling(code) {
            switch(code) {
                case 400:
                    return { status: "error", code: 400, msg: "잘못된 요청입니다." };
                case 401:
                    return { status: "error", code: 401, msg: "로그인 만료" };
                case 404:
                    return { status: "error", code: 404, msg: "Not Found" };
                case 500:
                    return { status: "error", code: 500, msg: "서버 에러" };
                default:
                    return { status: "error", code: code , msg: "unknown" };
            }
        },
    },
})
new Vue({
    router,
    store,
    vuetify,
    axios,
    render: h => h(App)
}).$mount('#app')

/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: 페이지네이션 컴포넌트     
┣ @작성: 신우균, 2022-01-20                        
┣ @내역: 신우균, 2022-01-20, 최초등록
        신우균, 2022-02-15, startPage 계산 알고리즘 수정
        신우균, 2022-04-06, v-btn > input tag로 변경
                          endPageButton 에러 수정
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <div class="pagination">
        <input
            class="pagination__link pagination__action"
            type="button"
            value="≪"
            v-show="pageNumbers[pageNumbers.length - 1] > paginationLength"
            @click="firstPageButton()"
        >
        <input
            class="pagination__link pagination__action"
            type="button"
            value="＜"
            v-show="pageNumbers[pageNumbers.length - 1] > paginationLength"
            @click="prevPageButton()"
        >
        <input
            class="pagination__link"
            type="button"
            :value="item"
            v-for="(item, index) in pageNumbers" :key="index"
            v-bind:style="buttonHighlight(item)"
            @click="pageButton(item)"
        >
        <input
            class="pagination__link pagination__action"
            type="button"
            value="＞"
            v-show="!(pageNumbers[pageNumbers.length - 1] == endPage)"
            @click="nextPageButton()"
        >
        <input
            class="pagination__link pagination__action"
            type="button"
            value="≫"
            v-show="!(pageNumbers[pageNumbers.length - 1] == endPage)"
            @click="endPageButton()"
        >
    </div>
</template>

<script>
export default {
    props: {
        paginationLength: {
            type: Number,
            require: true,
            default: 1,
        },
        setPage: {
            type: Number,
            require: true,
            default: 1,
        },
        endPage : {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            pageNumbers: [],
            currentPage: null,
        }
    },
    created() {
        this.init();
    },
    watch: {
        paginationLength() { this.init(); },
        setPage() { this.init(); },
        endPage() { this.init(); }
    },
    methods: {
        /**********************************************************************
         *  @method     : Pagination setting
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        init() {
            this.currentPage = this.setPage;
            let start = (this.currentPage -1) / this.paginationLength;
            // 형변환으로 소수점 버림 처리
            start = parseInt(start) * this.paginationLength + 1;
            this.pageNumbers = [];
            for (let i = 0; i < this.paginationLength; i++) {
                if (i >= this.endPage) {
                    break;
                }
                if (start > this.endPage) {
                    break;
                }
                if (start + i === this.endPage) {
                    this.pageNumbers.push(start + i);
                    break;
                }
                this.pageNumbers.push(start + i);
            }
        },

        /*********************************************************************
         *  @method     : 첫 번째(맨 앞으로) 페이지네이션 버튼 클릭 이벤트 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         ********************************************************************/
        firstPageButton() {
            this.pageNumbers = [];
            for (let i = 0; i < this.paginationLength; i++) {
                this.pageNumbers[i] = i + 1;
            }
            this.currentPage = 1;
        // 부모 컴포넌트 메소드 호출
            this.$parent.dataLoad(this.currentPage);
        },

        /*********************************************************************
         *  @method     : 이전 페이지네이션 버튼 클릭 이벤트 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         ********************************************************************/
        prevPageButton() {
            const pageNumberCopy = this.pageNumbers;
            this.pageNumbers = [];

            const endPoint = pageNumberCopy[0] - 1;
            // Pagination button setting
            for (let i = 0; i < this.paginationLength; i++) {
                this.pageNumbers[this.paginationLength - 1 - i] = endPoint - i;
            }
            // CurrentPage setting
            this.currentPage = this.pageNumbers[0];
            // 부모 컴포넌트 메소드 호출
            this.$parent.dataLoad(this.currentPage);
        },

        /*********************************************************************
         *  @method     : 페이지네이션 버튼 클릭 이벤트 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         ********************************************************************/
        pageButton(pageNum) {
            this.currentPage = pageNum;
            // this.$emit("update:currentPage", pageNum);
            this.buttonHighlight(pageNum);
            // 부모 컴포넌트 메소드 호출
            this.$parent.dataLoad(pageNum);
        },

        /*********************************************************************
         *  @method     : 다음 페이지네이션 버튼 클릭 이벤트 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         ********************************************************************/
        nextPageButton() {
            const startPoint = this.pageNumbers[this.paginationLength - 1] + 1;
            this.pageNumbers = [];

            // Pagination button setting
            for (let i = 0; i < this.paginationLength; i++) {
                this.pageNumbers[i] = startPoint + i
            }

            // Pagination last button setting
            if (this.pageNumbers[this.paginationLength - 1] > this.endPage) {
                // Count to number of buttons to remove
                let removeCount = this.endPage 
                                - this.pageNumbers[this.paginationLength - 1];
                // Convert negativce number to integer
                removeCount = Math.abs(removeCount);
                // Removce button from array 
                for (let i = 0; i < removeCount; i++) {
                    this.pageNumbers.pop()
                }
            }
            // CurrentPage setting
            this.currentPage = this.pageNumbers[0];
            // 부모 컴포넌트 메소드 호출
            this.$parent.dataLoad(this.currentPage);
        },

        /*********************************************************************
         *  @method     : 맨 마지막 페이지네이션 버튼 클릭 이벤트 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         ********************************************************************/
        endPageButton() {
            let startPage = null;
            if ((this.endPage % this.paginationLength) == 0) {
                startPage = (this.endPage - this.paginationLength) + 1;
            } else {
                startPage = this.endPage - ((this.endPage % this.paginationLength) -1);
            }
            this.pageNumbers = [];
             for (let i = 1; i <= this.paginationLength; i++) {
                if (i > this.endPage) {
                    break;
                }
                if (startPage > this.endPage) {
                    break;
                }
                this.pageNumbers.push(startPage++);
            }
            // 현재 페이지 설정
            this.currentPage = this.endPage;
            // 부모 컴포넌트 메소드 호출
            this.$parent.dataLoad(this.currentPage);
        },

        /*********************************************************************
         *  @method     : 현재 페이지 버튼 하이라이트 CSS 
         *  @param      { int }
         *  @returns    { object }
         ********************************************************************/
        buttonHighlight(target){
            // console.log(target, this.currentPage);
            if (target == this.currentPage) {
                return { 
                    backgroundColor: "#70C9EF",
                    borderColor: "#70C9EF",
                    color: "#FFFFFF"
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
    
    .pagination {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 20px 0px;
        padding-bottom: 50px;
        font-family: 'Noto Sans KR', sans-serif;

        &__link {
            min-width: 35px;
            height: 35px;
            border: 1px solid #dee2e6;
            padding: 0px 11px;
            border-radius: 30px;
            font-size: 16px;
            line-height: 33px;
            margin-left: 6px;
            margin-top: 10px;
        }

        &__action {
            line-height: 30px;
            font-size: 12px;
            letter-spacing: -1;
        }
    }
</style>
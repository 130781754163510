import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageData: {
      "currentPage": null,
      "scrollY": null,
    },
  },
  getters: {
    getPageData(state) { return state.pageData; },
  },
  mutations: {
    setPageData(state, value) {
      state.pageData = value;
  }
  },
  actions: {
  },
  modules: {
  }
})
